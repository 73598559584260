/**
 * Mesh Blog page
 * Autogenerates blog posts from blog-posts folder
 *
 * @author Anja Bisako <anja.bisako@thingos.io>
 * @version 0.0.1
 */
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { Icons } from '@thingos/design-icons';

import { getCategoryColor } from '../components/BlogLayout';
import { Contact } from '../components/Contact';
import { Container } from '../components/Container';
import { MenuLink } from '../components/MenuLink';
import { MeshlightingFooter } from '../components/MeshlightingFooter';
import { Page } from '../components/Page';
import { HeaderH2, HeaderH3, Subtitle, Title, WideBody } from '../components/Typography';
import { IndexLayout } from '../layouts';
import lightingColors from '../styles/lighting';

const FeaturedPostStyle = styled.div({
	display: 'flex',
	flexDirection: 'column',
	color: '#fff',
	marginTop: '2rem',
	borderRadius: 8,
	boxShadow: '0 3px 8px rgba(0, 0, 0, 0.24)',
	backgroundColor: lightingColors.background.card,
	transition: '0.5s',

	'&:hover': {
		backgroundColor: lightingColors.background.light,
		boxShadow: '0 5px 15px rgba(0, 0, 0, 0.4)',
		transition: '0.5s',
	},
});

export const PostRowStyle = styled.div({
	display: 'grid',
	gridTemplateColumns: '48% 48%',
	columnGap: '4%',
	color: '#fff',
	marginTop: '2rem',
	marginBottom: '2rem',
	'@media screen and (max-width: 600px)': {
		gridTemplateColumns: 'auto',
	},
});

export const PostColumnStyle = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: '3rem',
	backgroundColor: lightingColors.background.card,
	borderRadius: 8,
	boxShadow: '0 3px 8px rgba(0, 0, 0, 0.24)',
	transition: '0.5s',

	'&:hover': {
		backgroundColor: lightingColors.background.light,
		boxShadow: '0 5px 15px rgba(0, 0, 0, 0.4)',
		transition: '0.5s',
	},
});

export const PostCategoryStyle = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	alignItems: 'baseline',
	marginBottom: '1rem',
	'@media screen and (max-width:600px)': {
		flexDirection: 'column',
		'>*': { marginTop: '0.3rem' },
	},
});

export const StyledImage = styled.div({
	marginBottom: '1.5rem',
	height: '21rem',
	maxHeight: '21rem',
	overflow: 'hidden',
	'@media screen and (max-width: 600px)': {
		height: '15rem',
	},
});

const StyledFeaturedImage = styled.div({
	marginBottom: '1.5rem',
	height: '25rem',
	maxHeight: '25rem',
	overflow: 'hidden',
	'@media screen and (max-width: 600px)': {
		height: '15rem',
	},
});

export const InnerPaddingStyle = styled.span({
	padding: '1rem 2rem 1rem 2rem',
});

const AllpostsStyle = styled.div({
	color: '#fff',
	fontWeight: 600,
	fontSize: '16pt',
	display: 'flex',
	alignItems: 'center',
	userSelect: 'none',
});

const DropdownStyle = styled.div({
	color: '#fff',
	fontWeight: 600,
	fontSize: '16pt',
	position: 'absolute',
	zIndex: 1000,
	backgroundColor: lightingColors.background.card,
	margin: '1.5rem 0',
	userSelect: 'none',
	borderRadius: '6px',
});

const DropdownCategory = styled.div({
	'&:hover': {
		backgroundColor: lightingColors.background.light,
	},
	padding: '1rem 2rem 1rem 1rem',
	borderRadius: '6px',
});

function formatDate(dateString: string) {
	const postDate = new Date(dateString);
	const dateLabel = `${postDate.getDate().toString().padStart(2, '0')}.${(postDate.getMonth() + 1)
		.toString()
		.padStart(2, '0')}.${postDate.getFullYear()}`;
	return dateLabel;
}

const IndexPage: React.FC<PageProps> = ({ data: posts }: { data: any }) => {
	const { t } = useTranslation(['developers', 'blog']);

	const featuredPost = posts.allMdx.edges[0].node.frontmatter;
	const featuredPostImgPath: string = featuredPost.featuredImagePath;
	let featuredPostImg = getImage(posts.defaultImage); // default featured post image
	posts.allFile.edges.map((image: any) => {
		if (image.node.base === featuredPostImgPath) {
			featuredPostImg = getImage(image.node);
		}
	});
	const dateLabel = formatDate(featuredPost.date);

	const categoryItems = posts.allMdx.edges.map(
		(item: { node: { frontmatter: { category: string } } }) => item.node.frontmatter.category
	) as string[];
	categoryItems.unshift(t('blog:filter.allposts'));
	const uniqueCategories = Array.from(new Set(categoryItems));

	const [activeCategory, setActiveCategory] = React.useState(0);
	const [isDropdownOpen, setDropdown] = React.useState(false);
	const toggleDropdown = () => {
		setDropdown(!isDropdownOpen);
	};
	const handleCategoryChange = (categoryIdx: number) => {
		setActiveCategory(categoryIdx);
		setDropdown(!isDropdownOpen);
	};

	return (
		<IndexLayout>
			<Page>
				<Container>
					<Title>
						<HeaderH2>
							{t('blog:title')}
							<p style={{ display: 'inline', color: lightingColors.highlight.orange }}>.</p>
						</HeaderH2>
						<Subtitle>{t('blog:subtitle')}</Subtitle>
					</Title>
				</Container>

				<Container>
					<div style={{ display: 'flex' }}>
						<AllpostsStyle onClick={toggleDropdown}>
							{activeCategory === 0 ? t('blog:filter.allposts') : uniqueCategories[activeCategory]}
							&nbsp;
							<Icons kind="ui/arrowDown" color={lightingColors.highlight.orange} />
						</AllpostsStyle>
					</div>
					{isDropdownOpen && (
						<DropdownStyle>
							{uniqueCategories.map((categoryItem: string, categoryIdx: number) => {
								return (
									<DropdownCategory
										key={categoryItem}
										onClick={() => handleCategoryChange(categoryIdx)}
									>
										{categoryItem}
									</DropdownCategory>
								);
							})}
						</DropdownStyle>
					)}

					{activeCategory == 0 && (
						<MenuLink to={featuredPost.path}>
							<FeaturedPostStyle>
								<StyledFeaturedImage>
									{featuredPostImg && (
										<GatsbyImage
											image={featuredPostImg}
											imgStyle={{ borderRadius: '8px 8px 0px 0px', height: '25rem' }}
											alt={featuredPostImgPath}
											style={{ flex: 1 }}
										/>
									)}
								</StyledFeaturedImage>

								<InnerPaddingStyle>
									<PostCategoryStyle>
										<div
											style={{
												paddingLeft: '10px',
												paddingRight: '10px',
												borderRadius: '16px',
												backgroundColor: getCategoryColor(featuredPost.category),
												marginRight: '10px',
											}}
										>
											<strong
												style={{
													marginTop: 0,
													color: lightingColors.white,
												}}
											>
												{featuredPost.category}
											</strong>
										</div>
										<div>– {dateLabel}</div>
									</PostCategoryStyle>
									<HeaderH3 style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
										{featuredPost.title}
									</HeaderH3>
									<WideBody>{featuredPost.description}</WideBody>
								</InnerPaddingStyle>
							</FeaturedPostStyle>
						</MenuLink>
					)}

					<PostRowStyle>
						{posts.allMdx.edges
							.filter((edge: any, index: number) => {
								return (
									(activeCategory == 0 && index !== 0) ||
									(activeCategory != 0 &&
										uniqueCategories[activeCategory] == edge.node.frontmatter.category)
								);
							})
							.map((edge: any, index: number) => {
								const postInfo: {
									path: any;
									category: string;
									date: string;
									title: string;
									description: string;
									featuredImagePath: string;
								} = edge.node.frontmatter;

								const imgPath = postInfo.featuredImagePath;
								let featuredImg: any = null;
								posts.allFile.edges.map((image: any) => {
									if (image.node.base === imgPath) {
										featuredImg = getImage(image.node);
									}
								});
								const dateLabel = formatDate(postInfo.date);

								// each post must contain a featured image
								if (featuredImg != null) {
									return (
										<PostColumnStyle key={index}>
											<MenuLink to={postInfo.path}>
												<StyledImage>
													<GatsbyImage
														image={featuredImg}
														alt={imgPath}
														style={{ borderRadius: '8px 8px 0px 0px', height: '100%' }}
													/>
												</StyledImage>
											</MenuLink>
											<InnerPaddingStyle>
												<PostCategoryStyle>
													<div
														style={{
															paddingLeft: '10px',
															paddingRight: '10px',
															borderRadius: '16px',
															backgroundColor: getCategoryColor(postInfo.category),
														}}
													>
														<strong
															style={{
																marginTop: 0,
																color: lightingColors.white,
															}}
														>
															{postInfo.category}
														</strong>
													</div>
													<div style={{ paddingLeft: '10px' }}>– {dateLabel}</div>
												</PostCategoryStyle>
												<MenuLink to={postInfo.path}>
													<HeaderH3 style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
														{postInfo.title}
													</HeaderH3>
												</MenuLink>
												<WideBody>{postInfo.description}</WideBody>
											</InnerPaddingStyle>
										</PostColumnStyle>
									);
								} else {
									return <div />;
								}
							})}
					</PostRowStyle>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground" id="Contact">
				<Container>
					<Contact
						title={t('developers:contact.title')}
						text={t('developers:contact.description')}
						buttonText={t('developers:contact.button')}
						url={'mailto:info@thingos.io'}
					/>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<MeshlightingFooter />
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query BlogIndex($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		allMdx(
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { published: { eq: true } } }
		) {
			edges {
				node {
					frontmatter {
						path
						category
						date
						title
						description
						featuredImagePath
					}
				}
			}
		}
		allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(jpeg)/" }
				relativeDirectory: { eq: "blog-posts" }
			}
		) {
			edges {
				node {
					base
					childImageSharp {
						gatsbyImageData(height: 1200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
					}
				}
			}
		}
		defaultImage: file(relativePath: { eq: "blog-posts/meshapp-screenshot.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
			}
		}
	}
`;

export default IndexPage;
